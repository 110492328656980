<template>
  <v-toolbar>
    <router-link to="/landing" class="logo">
      <img class="logo" src="../assets/wizards-logo.png" />
    </router-link>
    <v-toolbar-title>
      Wizards Admin UI
      <span class="env" v-if="!isProduction">Development</span>
    </v-toolbar-title>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon medium>chevron_right</v-icon>
      </template>
      <template v-slot:item="props">
        <v-btn
          :to="props.item.href || ''"
          :class="!!props.item.href && 'disabled'"
        >
          {{ props.item.text }}
        </v-btn>
      </template>
    </v-breadcrumbs>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn @click="logout" flat v-if="loggedIn">Log Out</v-btn>
      <v-menu v-if="loggedIn" class="menu">
        <v-toolbar-title slot="activator">
          <v-icon>view_headline</v-icon>
        </v-toolbar-title>

        <v-list>
          <v-list-tile
            @click="accounts"
            v-if="permissionAllowed('account_admin', true)"
          >
            <v-list-tile-title>Search for Accounts</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            @click="entitlements"
            v-if="permissionAllowed('entitlements', true)"
          >
            <v-list-tile-title>Search Entitlements</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            @click="create"
            v-if="permissionAllowed('admin/account_admin')"
          >
            <v-list-tile-title>Create Accounts</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            @click="codeRedemption"
            v-if="permissionAllowed('coderedemption_offers')"
          >
            <v-list-tile-title>Code Redemption</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            @click="redemption"
            v-if="permissionAllowed('admin/redemption')"
          >
            <v-list-tile-title>
              Redemption (
              <span class="beta">Preview</span>
              )
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
  import _ from 'lodash';
  import axios from 'axios';
  export default {
    name: 'navbar',
    methods: {
      roles() {
        this.$router.push('/landing');
      },
      logout() {
        let encounteredError = false;
        let req = {
          method: 'delete',
          url: `${this.$store.getters.authURL}/auth/oauth/logout`,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
          error: '',
        };
        axios(req).catch((error) => {
          this.setError('Unable to log out: ' + error);
        });
        if (!encounteredError) {
          this.$store.commit('clear');
          this.$router.push('/login');
        }
      },
      entitlements() {
        this.$router.push('/entitlements');
      },
      accounts() {
        this.$router.push('/find');
      },
      create() {
        this.$router.push('/create');
      },
      codeRedemption() {
        this.$router.push('/codeRedemption');
      },
      redemption() {
        this.$router.push('/redemption/offers');
      },
      permissionAllowed(permission, general, action) {
        if (general) {
          return this.$store.getters.permissionGeneral(permission, action);
        }

        return this.$store.getters.permissionTo(permission, action);
      },
    },
    data() {
      return {
        breadcrumbRegex: /(:([^\ /]+))/g,
      };
    },
    computed: {
      loggedIn() {
        return this.$store.getters.authenticated;
      },
      isProduction() {
        return this.$store.getters.isProduction;
      },
      breadcrumbs() {
        if (_.size(this.$route.matched) > 0) {
          var route = this.$route.matched[0]; //maybe don't use the first one?
          let replaced = _.map(route.meta.breadCrumb, (item) => {
            return _.mapValues(item, (value) => {
              let matched = value.match(this.breadcrumbRegex);
              let result = value;
              _.forEach(matched, (match) => {
                let m = match.substring(1); //chop off the :
                let regex = new RegExp(match, 'g');
                result = result.replace(regex, route.instances.default[m]);
              });
              return result;
            });
          });
          return replaced;
        }
        return [];
      },
    },
  };
</script>

<style scoped>
  .beta {
    color: red;
  }
  .logo {
    width: auto;
    height: 100%;
  }
  .logo img {
    position: relative;
    top: 2px;
    padding: 7px;
  }
  .menu {
    padding-left: 25px;
  }
  .env {
    color: #d00;
    font-size: 12px;
    font-weight: normal;
    padding-left: 15px;
  }
</style>
